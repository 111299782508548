import type { NextPage } from "next";
import React, { useEffect, useState } from "react";
import { Text } from "@chakra-ui/react";
import useAPI from "@lib/hooks/api";
import { AnnouncementsData } from "@lib/components/announcement-cards";
import AnnouncementContainer from "@lib/components/announcement-container";
import { logEvent } from "@amplitude/analytics-browser";
import { AmplitudeEvents } from "@lib/types";
import { AnalysisResponseData } from "@lib/components/dashboard-analysis";
import { getDateDifference } from "utils/formatDate";
import Loader from "@lib/components/loader";

const Home: NextPage = () => {
  const { data, error } = useAPI<AnnouncementsData>("/api/announcements", {
    refreshInterval: (data) => (data?.analyzing ? 200 : 0),
  });
  const [analysingLoading, setAnalysingLoading] = useState<string>("");

  const {
    data: analysisData,
    error: analysisError,
    isValidating,
  } = useAPI<AnalysisResponseData>("/api/user/analysis", {
    refreshInterval: 1000,
  });

  useEffect(() => {
    let loading = "";
    if (!analysisData || analysisData.data.length === 0) return;
    // const loadingState = analysisData.data.findIndex(
    //   (data) =>
    //     data.twitterUsername.status === "error" &&
    //     data.twitterUsername.value.toLowerCase() === "still searching"
    // );

    const analysingState = analysisData.data.findIndex((data) => {
      return (
        data.twitterUsername.status === "success" &&
        (data.lastChecked === null ||
          getDateDifference(new Date(), data.lastChecked) > 0)
      );
    });

    if (analysingState >= 0) {
      loading = "analysing";
    } else if (analysingState < 0) {
      loading = "completed";
    }
    console.log("loading", loading);
    setAnalysingLoading(loading);
  }, [analysisData, analysisError, isValidating]);

  useEffect(() => {
    logEvent(AmplitudeEvents.DashboardVisited);
  }, []);

  if (error) {
    return <Text>{error.message}</Text>;
  }

  if (!data && !error) {
    return <Loader message="Announcement data is loading..." />;
  }

  return (
    <AnnouncementContainer
      data={data}
      analysisData={analysisData}
      analysisLoading={analysingLoading}
    />
  );
};

export default Home;
